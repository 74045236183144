import React, { useState, useEffect, useRef  } from 'react';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import axios from 'axios';
import {NewNote, PreviousNotes} from "./notes/Notes";
import {ArrowIconAnimation} from './components/ArrowIconAnimation/ArrowIconAnimation.js';

import Fab from '@material-ui/core/fab';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import './App.css';

function App() {
  const [IsLoading,setIsLoading] = useState(true);
  const [noteValue,setNoteValue] = useState('');
  const [previousNotesLabel,setPreviousNotesLabel] = useState('Previous Notes');
  
  const [clickSaveNote,setClickSaveNote] = useState(0);

  setTimeout(function(){
    setIsLoading(false)
  },900);

  const handleSetNote = (e) => {
    console.log('handleSavenote',e)
    setNoteValue(e)
  }

  const handleSaveNote = () => {
    if(noteValue === '') return;
      axios
      .post('http://localhost:1337/vandelios', {note: noteValue})
      .then(response => {
          console.log('saved')
          setClickSaveNote(clickSaveNote+1);
          //clear note
          //refresh data to show new
      });
    }


  return (
    <div className={!IsLoading ? 'sectionPrimary main container':'main container'}>
      <header className="App-header">
        <Logo/>
        <div></div>
      </header>
      <section>
        <div className="container">
          <NewNote value={noteValue} handleSetNote={handleSetNote} />
          <RenderFabSaveIconLeft onClick={handleSaveNote} show={noteValue.length > 0 ? true : false} />
        </div>
        <SwipeableBottomSheet  overflowHeight={64} shadowTip={false}>
          <div className="draggableFromBottom">
            <div className="container">
                  <ArrowIconAnimation/>
                <div className="label">
                  {previousNotesLabel}
                </div>
                <PreviousNotes newNoteSaved={clickSaveNote} />
            </div>
          </div>
        </SwipeableBottomSheet>
      </section>
    </div>
  );
}

function RenderFabSaveIconLeft(props){

  let show = props.show;
 console.log(show);
  if (show) {
    return (
        <Fab onClick={props.onClick} color="primary" aria-label="save" >
          <SaveAltIcon />
        </Fab>
    )
  }else{
    return false;
  }
}

function Logo(){


  return (
    <div className="logo">
      <svg version="1.1" id="logosvg" className="App-logo" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="100%" height="592px" viewBox="0 0 535 592"><path  d="M413.554,131.328c0-17.606,0-34.44,0-51.427c12.78,0,25.208,0,37.878,0
    c0,38.118,0,76.244,0,114.699c-1.034,0.051-2.102,0.149-3.17,0.151c-8.51,0.013-17.021-0.067-25.529,0.057
    c-2.229,0.032-3.69-0.604-5.141-2.354c-12.982-15.677-26.069-31.267-39.13-46.879c-0.552-0.658-1.157-1.27-2.306-2.522
    c0,17.644,0,34.485,0,51.514c-12.772,0-25.176,0-37.777,0c0-38.217,0-76.346,0-114.822c4.428,0,8.752,0,13.076,0
    c5.268,0,10.539,0.107,15.803-0.052c2.139-0.065,3.529,0.616,4.884,2.249c12.803,15.432,25.679,30.803,38.542,46.186
    C411.431,129.021,412.238,129.865,413.554,131.328z"/>
  <path  d="M83.517,242.688c1.02,0,1.817,0,2.614,0c18.939,0,37.88-0.115,56.818,0.042
    c11.891,0.099,23.355,2.501,33.851,8.299c19.909,10.998,28.744,28.407,28.159,50.696c-0.768,29.278-19.392,49.986-48.79,54.581
    c-5.477,0.855-11.069,1.282-16.613,1.335c-17.52,0.166-35.042,0.061-52.564,0.061c-1.098,0-2.195,0-3.476,0
    C83.517,319.341,83.517,281.212,83.517,242.688z M122.478,273.564c0,17.561,0,35.572,0,53.616c6.776,0,13.365,0.332,19.908-0.072
    c11.838-0.732,20.618-8.474,22.727-19.993c0.68-3.716,0.648-7.658,0.323-11.443c-1.021-11.86-8.769-20.504-20.634-21.944
    C137.541,272.846,130.086,273.564,122.478,273.564z"/>
  <path  d="M252.973,523.335c-20.311-0.208-36.957-5.376-50.277-18.82
    c-12.694-12.811-17.597-28.551-16.354-46.305c2.119-30.255,25.272-50.774,53.454-54.44c16.915-2.201,33.234-0.295,47.982,8.829
    c20.521,12.696,29.497,31.54,28.006,55.424c-1.774,28.41-23.08,49.821-52.199,54.302
    C259.496,522.954,255.334,523.118,252.973,523.335z M276.933,462.977c-0.29-2.405-0.448-4.835-0.893-7.211
    c-2.044-10.921-10.476-19.465-20.551-20.938c-11.609-1.696-21.67,3.138-26.738,13.491c-4.708,9.618-4.751,19.604-0.134,29.295
    c4.348,9.128,11.955,13.959,21.992,14.156c9.77,0.191,17.309-4.231,22.279-12.752C275.771,474.075,276.779,468.653,276.933,462.977z
    "/>
  <path  d="M219.231,242.774c31.131,0,62.013,0,93.103,0c0,9.646,0,19.208,0,29.144
    c-18.257,0-36.521,0-54.95,0c0,4.575,0,8.783,0,13.364c16.059,0,32.094,0,48.298,0c0,9.441,0,18.511,0,27.956
    c-16.039,0-32.074,0-48.285,0c0,5.167,0,9.962,0,15.036c19.054,0,37.96,0,57.071,0c0,9.851,0,19.424,0,29.212
    c-31.715,0-63.367,0-95.236,0C219.231,319.342,219.231,281.21,219.231,242.774z"/>
  <path  d="M332.045,194.753c-13.168,0-25.923,0.033-38.677-0.084
    c-0.743-0.006-1.822-1.081-2.157-1.898c-2.146-5.238-4.187-10.521-6.127-15.838c-0.616-1.688-1.484-2.292-3.312-2.281
    c-12.863,0.08-25.727,0.09-38.59-0.008c-1.985-0.015-2.854,0.71-3.495,2.465c-1.907,5.223-3.906,10.415-6.008,15.562
    c-0.343,0.84-1.382,1.986-2.109,1.993c-12.547,0.123-25.097,0.088-38.083,0.088c0.495-1.303,0.829-2.31,1.252-3.277
    c15.828-36.222,31.68-72.434,47.46-108.677c0.951-2.184,2.002-3.175,4.601-3.131c10.834,0.185,21.674,0.1,32.511,0.056
    c1.63-0.007,2.705,0.189,3.489,1.992c16.13,37.082,32.345,74.127,48.54,111.18C331.538,193.345,331.691,193.815,332.045,194.753z
    M262.409,118.159c-3.775,9.784-7.314,18.955-10.904,28.255c7.424,0,14.434,0,21.823,0
    C269.688,136.995,266.169,127.888,262.409,118.159z"/>
  <path  d="M71.039,79.747c13.687,0,26.956-0.045,40.222,0.104
    c0.847,0.01,2.052,1.362,2.46,2.35c7.377,17.853,14.656,35.746,21.96,53.629c1.374,3.362,2.77,6.715,4.375,10.606
    c0.674-1.452,1.177-2.447,1.606-3.473c8.479-20.241,16.979-40.473,25.373-60.749c0.775-1.872,1.642-2.548,3.711-2.525
    c10.941,0.119,21.885,0.054,32.828,0.061c0.784,0,1.568,0.072,2.716,0.127c-1.993,4.772-3.874,9.325-5.792,13.86
    c-13.873,32.786-27.771,65.56-41.583,98.372c-0.826,1.961-1.75,2.73-3.947,2.705c-10.941-0.124-21.885-0.072-32.828-0.039
    c-1.605,0.005-2.749-0.115-3.525-1.963c-15.635-37.201-31.354-74.367-47.051-111.541C71.415,80.919,71.312,80.546,71.039,79.747z"/>
  <path  d="M420.127,357.583c-30.242,0-60.2,0-90.41,0c0-38.166,0-76.297,0-114.662
    c12.676,0,25.473,0,38.569,0c0,28.024,0,56.034,0,84.491c17.431,0,34.571,0,51.841,0
    C420.127,337.656,420.127,347.452,420.127,357.583z"/>
  <path  d="M172.083,405.803c0,38.262,0,76.314,0,114.604c-12.85,0-25.636,0-38.611,0
    c0-38.189,0-76.311,0-114.604C146.309,405.803,159.023,405.803,172.083,405.803z"/>
  <path id="dot"  d="M98.934,479.808c12.842-0.018,21.806,8.507,21.979,20.9
    c0.173,12.332-9.09,21.563-21.708,21.635c-12.695,0.072-21.843-8.872-21.855-21.366C77.339,488.471,86.152,479.826,98.934,479.808z"
    />
  </svg>
  <span>DailyNote</span>
</div>
  )
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
export default App;
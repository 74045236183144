import React, { useEffect, useState} from 'react';
import axios from 'axios';
import './notes.css';
import TextField from '@material-ui/core/TextField';
import Pencil from '@material-ui/icons/Edit';
import Trash from '@material-ui/icons/DeleteOutline';


export function NewNote(props) {
  const [dailyNoteField] = useState('What did you do today');
  const [putInYourThoughts] = useState('Put In Your Thoughts');

  const handleNoteKeyUp = e => {
      props.handleSetNote(e.target.value)
      console.log(e.target.value,props.value)
      localStorage.setItem('note', JSON.stringify(e.target.value));
  }
  if(props.handleSaveNote){
      console.log('handleSaveNote')
  }
  return(
      <form noValidate autoComplete="off">
        <TextField
            id="filled-textarea"
            label={dailyNoteField}
            placeholder={putInYourThoughts}
            multiline
            variant="filled"
            onKeyUp={handleNoteKeyUp}
            />
      </form>
  )
}



export function PreviousNotes(props) {
  const [data, setData] = useState([]);
  const [hasData, setHasData] = useState(data.length > 0 ? true : false);

  const handleSetData = (data) => {
    console.log(data)
    setData(data)
  }

  function getData() {
    axios
      .get('http://localhost:1337/vandelios')
      .then(response => {
          handleSetData(response.data)
      });
  }

  // /* Update when newNoteSaved changes */
useEffect(() => {  
  console.log('newNoteSaved changed')
  if(!hasData){
    getData()
  }
},[props.newNoteSaved])

  
 useEffect(()=>{ console.log('called')})

  // /* Update when newNoteSaved changes */
  // useEffect(()=>{
  //   if(!updateTimer.current) {
  //     getData();
  //   }
  // },[newNoteSaved])
  
  // /** Run on unmount **/
  React.useEffect(()=> {
    console.log('unmount ?')
    return () => {
      document.getElementById('previousNotes').classList.add('fadeOut')
    };
  }, []);

    
    // if (isLoading) return "Loading...";
  
    // if (error) return "An error has occurred: " + error.message;
  
    return (
      <div>
        <h2 className="previousNotesViewHeadline">Previous Notes</h2>
        <ul id="previousNotes"><HandleRenderNotes data={data} /></ul>
      </div>
    );
  }

  function HandleRenderNotes(props){
    const previousNotes = [];
        console.log(props.data);
        if(props.data.length > 0){
          for (let pn of props.data) {
              previousNotes.push(<SingleNote entry={pn} />);  
          }
        }
        
      return previousNotes
  }
  function SingleNote(props){
      return(
        <li key={props.entry.note}>
          <div>
            <div> {props.entry.note}</div>
            <div>
              <strong> {props.entry.updated_at}</strong>
              <div> 
                <Trash fontSize="small"/>
                <div className="spacer"></div>
                <Pencil fontSize="small"/>
              </div>
            </div>
          </div>
        </li>
      )
  }

import React from 'react';
import './ArrowIconAnimation.css';

export function ArrowIconAnimation (){
    return (
        <a class="arrow-icon">
            <span class="left-bar"></span>
            <span class="right-bar"></span>
        </a>
    )
}
